import {isValidPhoneNumber} from 'libphonenumber-js'
import {z} from 'zod'

import {InternalErrors} from '../../../ErrorMap'
import {handleAllowedCharacters} from '../../../utils'
import {RequestAccessSelfOnboardingFormData} from '../RequestAccessSelfOnboarding.types'

const MAX_EMAIL_CHARACTERS_LENGTH = 2000

export const RequestAccessSchema: z.ZodType<RequestAccessSelfOnboardingFormData> = z
  .object({
    fullName: z.string().trim().optional(),
    email: z
      .string()
      .trim()
      .optional()
      .refine((value) => !value || handleAllowedCharacters(value), InternalErrors.INVALID_EMAIL),
    mobileNumber: z
      .string()
      .trim()
      .optional()
      .refine(
        (value) => !value || (value && isValidPhoneNumber(value)),
        InternalErrors.INVALID_MOBILE_NUMBER
      ),
    comment: z
      .string()
      .trim()
      .max(MAX_EMAIL_CHARACTERS_LENGTH, InternalErrors.MAX_CHARACTERS_EXCEEDED)
      .optional(),
    permissions: z.record(z.boolean()).refine((o) => Object.keys(o).length > 0, {
      message: InternalErrors.FIELD_REQUIRED
    }),
    companyName: z
      .string({required_error: InternalErrors.FIELD_REQUIRED})
      .trim()
      .min(1, InternalErrors.FIELD_REQUIRED),
    market: z
      .string({required_error: InternalErrors.FIELD_REQUIRED})
      .trim()
      .min(1, InternalErrors.FIELD_REQUIRED),
    countryId: z
      .string({required_error: InternalErrors.FIELD_REQUIRED})
      .trim()
      .min(1, InternalErrors.FIELD_REQUIRED),
    customerIds: z
      .array(
        z.object({
          id: z
            .string()
            .nonempty(InternalErrors.FIELD_REQUIRED)
            .refine((value) => /^\d+$/.test(value), InternalErrors.FIELD_NUMBER)
        })
      )
      .min(1, InternalErrors.FIELD_REQUIRED),
    deliveryTicketNr: z
      .string()
      .refine((value) => /^\d+$/.test(value), InternalErrors.FIELD_NUMBER)
      .optional(),
    invoiceNr: z
      .string()
      .refine((value) => /^\d+$/.test(value), InternalErrors.FIELD_NUMBER)
      .optional(),
    invoiceNetTotal: z
      .string()
      .refine((value) => /^\d+$/.test(value), InternalErrors.FIELD_NUMBER)
      .optional(),
    onsiteAccess: z.boolean().optional(),
    allProjectsAccess: z.boolean().optional(),
    hubAccess: z.boolean().optional()
  })
  .superRefine((data, ctx) => {
    if (!data.fullName) {
      ctx.addIssue({
        path: ['fullName'],
        message: InternalErrors.FIELD_REQUIRED,
        code: z.ZodIssueCode.custom
      })
    }

    if (data.onsiteAccess && !data.mobileNumber) {
      ctx.addIssue({
        path: ['mobileNumber'],
        message: InternalErrors.FIELD_REQUIRED,
        code: z.ZodIssueCode.custom
      })
    }

    if (data.hubAccess && !data.email) {
      ctx.addIssue({
        path: ['email'],
        message: InternalErrors.FIELD_REQUIRED,
        code: z.ZodIssueCode.custom
      })
    }

    if (!data.email && !data.mobileNumber && !data.onsiteAccess && !data.hubAccess) {
      ctx.addIssue({
        path: ['email'],
        message: InternalErrors.PHONE_OR_EMAIL_REQUIRED,
        code: z.ZodIssueCode.custom
      })
      ctx.addIssue({
        path: ['mobileNumber'],
        message: InternalErrors.PHONE_OR_EMAIL_REQUIRED,
        code: z.ZodIssueCode.custom
      })
    }
  })
